import './App.css';
import About from './routes/About';
import Contact from './routes/Contact';
import Home from './routes/Home';
import { Routes, Route } from 'react-router-dom'
import ScheduleDemo from './components/ScheduleDemo';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

import AppDev from './NavComponents/AppDev';
import Quality from './NavComponents/Quality';
import Automation from './NavComponents/Automation';
import DataAna from './NavComponents/DataAna';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/scheduleDemo' element={<ScheduleDemo />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />

        <Route path='/appdev' element={<AppDev />} />
        <Route path='/quality' element={<Quality />} />
        <Route path='/automation' element={<Automation />} />
        <Route path='/dataana' element={<DataAna />} />
      </Routes>
    </div>
  );
}

export default App;
