import React, { useState } from 'react';
import Footer from '../components/Footer';
import HeroImg from '../components/HeroImg';
import Navbar from '../components/Navbar';
import BottomContent from '../components/BottomContent';
import './Contact.css';
// import swal from 'sweetalert2'
import CountryCode from '../Data/CountryCode';
import axios from 'axios';

import img1 from '../assets/HeroImg/contact-bg.svg'

export default function Contact() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [countrycode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (number.length === 0 || email.length === 0) {
      setError(true);
    } else {
      const url = "https://vedalytics.com/contactData.php"
      let fData = new FormData();
      fData.append('firstname', firstname);
      fData.append('lastname', lastname);
      fData.append('countrycode', countrycode);
      fData.append('number', number);
      fData.append('email', email);
      fData.append('company', company);
      fData.append('role', role);
      fData.append('message', message);

      axios.post(url, fData)
        .then(response => alert("Your form has been submitted successfully!"))
        .catch(error => alert("Something went wrong Please Try Again Later!"));

      setFirstname('');
      setLastname('');
      setCountryCode('');
      setNumber('');
      setEmail('');
      setCompany('');
      setRole('');
      setMessage('');
    }
  }



  const HeroImgContact = {
    heading: 'Contact Us',
    subheading: 'Know More About Us or Need Support for your Next Project ?',
    text: `Regardless of what you're looking for, we’re always happy to answer any questions or requests that you may have. Fill out the form below and we’ll get back to you as soon as possible.`,
    img: img1,
    validate: false
  }
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgContact.heading}
        subheading={HeroImgContact.subheading}
        text={HeroImgContact.text}
        img={img1}
        validate={HeroImgContact.validate} />

      {/* Contact Form Section */}
      <section className='my-5'>
        <div className="container my-2" data-aos="fade-up" data-aos-anchor-placement="top-center">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4><b>What will be the Next Step ?</b></h4>
              <p className="text-secondary">You are one step away to build your perfect product.</p>
              <ul className="vertical-timeline">
                <li>
                  <h6><b>We Will Prepare a Proposal.</b></h6>
                  <p className="text-secondary">According to your product requirements, we will prepare a proposal for you.
                  </p>
                </li>
                <li>
                  <h6><b>Together we will Discuss.</b></h6>
                  <p className="text-secondary">We will schedule a Virtual or Physical meeting to discuss the prepared proposal
                    with you or your team.
                  </p>
                </li>
                <li>
                  <h6><b>Start Building</b></h6>
                  <p className="text-secondary">After Finalizing all the requirements, Methods, payments etc. we will start
                    working on your product.
                  </p>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="card border-0">
                <div className="card-header text-center pb-0 border">
                  <p>Tell us about your Requirements and we will Discuss with you.</p>
                </div>
                <div className="card-body " style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="firstname">FirstName</label>
                        <input type="text" className='form-control' placeholder='Your first name' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <label htmlFor="lastname">LirstName</label>
                        <input type="text" className='form-control' placeholder='Your last name' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="country">Country</label>
                    <select name="countrycode" id="countrycode" className='form-control' value={countrycode} onChange={(e) => setCountryCode(e.target.value)}>
                      <option value="" disabled>--Select Country--</option>
                      {
                        CountryCode.map((country, i) => {
                          return <option key={i} >{country.name} ({country.dial_code})</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="number">Mobile Number<span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                    <input type="number" className='form-control' placeholder='Your mobile number' value={number} onChange={(e) => setNumber(e.target.value)} required />
                    {error && number.length <= 0 ? <span style={{ color: 'red' }}>mobile number required</span> : ''}
                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="email">Email Id<span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                    <input type="email" className='form-control' placeholder='Your email id' value={email} onChange={(e) => setEmail(e.target.value)} required />
                    {error && email.length <= 0 ? <span style={{ color: 'red' }}>email id required</span> : ''}

                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="company">Company</label>
                    <input type="text" className='form-control' placeholder='Your company name' value={company} onChange={(e) => setCompany(e.target.value)} />
                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="role">Current Role</label>
                    <input type="text" className='form-control' placeholder='Your current role' value={role} onChange={(e) => setRole(e.target.value)} />
                  </div>
                  <div className="input-field my-3">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" className='form-control' cols="30" rows="4" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                  <div className="button my-3">
                    <button className='btn btn-warning text-light' style={{ width: '100%' }} onClick={handleSubmit}>submit</button>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>
      </section>


      <BottomContent />
      <Footer />
    </>
  )
}
