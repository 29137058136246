import React from 'react';
import Navbar from '../components/Navbar';
import HeroImg from '../components/HeroImg';
import QualityIcon from '../assets/img/QualityAssur.png';
import ServicesCard from '../components/ServicesCard';
import { Link } from "react-router-dom"
import BottomContent from '../components/BottomContent';
import Footer from '../components/Footer';

export default function Quality() {
  const HeroImgQuality = {
    heading: 'Quality Assurence',
    subheading: 'Welcome to the World of Development & Technology',
    text: `At Vedalytics, we prioritize delivering high-quality software solutions to our clients. Quality Assurance (QA) plays a crucial role in ensuring that our applications meet the highest standards of functionality, reliability, and user experience.`,
    img: QualityIcon,
    validate: false
  }

  const qualityContext = [
    {
      img: QualityIcon,
      heading: 'Comprehensive Testing',
      text: `We employ a comprehensive testing approach to identify and rectify any issues or bugs in your application. Our QA team conducts various types of testing, including functional testing, performance testing, security testing, usability testing, and compatibility testing. By covering all aspects of your application, we ensure that it functions flawlessly across different platforms, devices, and environments.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: QualityIcon,
      heading: 'Test Planning and Strategy',
      text: `Before starting the testing process, our QA team creates a detailed test plan and strategy tailored to your application. This includes defining test objectives, identifying test scenarios and cases, and determining the appropriate testing techniques and tools. By meticulously planning the testing phase, we ensure maximum coverage and efficiency in identifying and resolving potential issues.`,
      bg: 'warning',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: QualityIcon,
      heading: 'Performance and Load Testing',
      text: `We understand the importance of a high-performing application, especially in scenarios with heavy user traffic. Our QA team conducts performance and load testing to evaluate your application's responsiveness, stability, and scalability under various conditions. By simulating real-world usage scenarios, we identify bottlenecks, optimize performance, and ensure your application can handle the expected user load.`,
      bg: 'danger',
      headingColor: 'warning',
      textColor: 'light'
    },
    {
      img: QualityIcon,
      heading: 'Security Testing',
      text: `Security is a top priority for any application handling sensitive data or transactions. Our QA team conducts rigorous security testing to identify vulnerabilities, validate data encryption, and ensure compliance with industry standards. By implementing security best practices and performing vulnerability assessments, we protect your application and its users from potential threats.`,
      bg: 'success',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: QualityIcon,
      heading: 'Usability Testing',
      text: `User experience is a key differentiator in today's competitive landscape. Our QA team conducts usability testing to evaluate your application's intuitiveness, ease of navigation, and overall user-friendliness. By collecting valuable feedback from real users, we identify areas for improvement and make iterative enhancements to enhance user satisfaction and engagement.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    }
  ]
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgQuality.heading}
        subheading={HeroImgQuality.subheading}
        text={HeroImgQuality.text}
        img={QualityIcon}
        validate={HeroImgQuality.validate}
      />
      <div className="container-fluid">
        {
          qualityContext.map((card, i) => {
            return (
              <div className="row">
                <div className="col-md-12 my-1" data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <ServicesCard key={i} img={card.img} heading={card.heading} text={card.text} bg={card.bg} headingColor={card.headingColor} textColor={card.textColor} />
                </div>
              </div>
            )
          })
        }
      </div>
      {/* Another Section */}
      <section className='mb-5' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>

      <BottomContent />
      <Footer />
    </>
  )
}
