import React from 'react'
 
const ServicesCard = ({ img,heading, text, bg, headingColor, textColor }) => {
    return (
        < >
            <div className={`card p-2 mb-3 bg-${bg} h-100 w-100`} style={{boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}}>
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <img src={img} alt="icon" className='img-fluid' style={{height:'250px'}} />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h4 className={`card-title text-${headingColor}`}>{heading}</h4>
                            <p className={`card-text text-${textColor}`}>
                                {text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesCard
