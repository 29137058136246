import React from 'react'
import './Home.css'
import Footer from '../components/Footer'
import HeroImg from '../components/HeroImg'
import Navbar from '../components/Navbar'
import img1 from '../assets/HeroImg/home.png'
import BottomContent from '../components/BottomContent'
// import img from '../assets/img/Component 5 – 1.svg'
import guru from '../assets/img/c1.png'
import prabha from '../assets/img/c2.png'
import vidya from '../assets/img/c3.png'
import suraksha from '../assets/img/c4.png'

// import appdev from '../assets/img/App&Dev.jpeg'
// import data from '../assets/img/Data&Ana.jpeg'
// import devops from '../assets/img/Devops.jpeg'
// import quality from '../assets/img/Quality.jpeg'
import appdev from '../assets/img/AppDeve.png'
import data from '../assets/img/DataAnalysis.png'
import devops from '../assets/img/Devops.png'
import quality from '../assets/img/QualityAssur.png'
import { Link } from 'react-router-dom'

// import mobileApp from '../assets/img/mobile-application.svg'
// import list from '../assets/img/shopping-list.svg'
// import setting from '../assets/img/settings.svg'

export default function Home() {
    const HeroImgHome = {
        heading: 'Delivering a personalized, client-centric digital experience using',
        subheading: 'Emerging and Disruptive technology.',
        text: 'At Vedalytics, we are constantly innovating at our labs and R&D center, using cutting edge emerging tools and technology for the betterment of humanity and the world around us.',
        img: img1,
        validate: true
    }

    return (
        <>
            <Navbar />
            <HeroImg
                heading={HeroImgHome.heading}
                subheading={HeroImgHome.subheading}
                text={HeroImgHome.text}
                img={HeroImgHome.img}
                validate={HeroImgHome.validate} />


            {/* Services Sectionh */}
            <section >
                <div className="container-fluid" data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className="heading mx-2" data-aos="fade-up" data-aos-anchor-placement="top-center">
                        <h3 className='text-primary'><b>Our Services</b></h3>
                        <p className="ser-content mx-auto">We offer project services where we take full responsibility of
                            deliverables <br /> using <span className='text-warning'>BOT</span> & <span className='text-warning'>BOM </span>
                            models</p>
                    </div>
                    <div className="contents row p-4 my-5">
                        <div className="service col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <Link to='/appdev' style={{ textDecoration: "none", color: 'black' }}>
                                <div className="card border-0 my-3" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                                    <img className="card-img-top p-1" src={appdev} alt="CardImg" />
                                    <div className="card-body">
                                        <h5 className="card-title">Application Development</h5>
                                        <p className="card-text text-secondary">We offer project services of Android and iOS Development.</p>
                                        {/* <a href="/appdevelopment" className="btn btn-warning text-light">Know More</a> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="service col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <Link to='/quality' style={{ textDecoration: "none", color: 'black' }}>
                                <div className="card border-0 my-3" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                                    <img className="card-img-top p-1" src={quality} alt="CardImg" />
                                    <div className="card-body">
                                        <h5 className="card-title">Quality Assurance</h5>
                                        <p className="card-text text-secondary">QA Testing, Quality Assurance and Digital Transformation.</p>
                                        {/* <a href="/appdevelopment" className="btn btn-warning text-light">Know More</a> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="service col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <Link to='/automation' style={{ textDecoration: "none", color: 'black' }}>
                                <div className="card border-0 my-3" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                                    <img className="card-img-top p-1" src={devops} alt="CardImg" />
                                    <div className="card-body">
                                        <h5 className="card-title">Automation & DevOps</h5>
                                        <p className="card-text text-secondary">We offer services of DevOps, RPA and Machine Learning.</p>
                                        {/* <a href="/appdevelopment" className="btn btn-warning text-light">Know More</a> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="service col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <Link to='/dataana' style={{ textDecoration: "none", color: 'black' }}>
                                <div className="card border-0 my-3" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                                    <img className="card-img-top p-1" src={data} alt="CardImg" />
                                    <div className="card-body">
                                        <h5 className="card-title">Data & Analytics</h5>
                                        <p className="card-text text-secondary">Business Analytics Solutions to establish businesses.</p>
                                        {/* <a href="/appdevelopment" className="btn btn-warning text-light">Know More</a> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="service">
                            <div className="card border-0 my-3" style={{ width: "18rem", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                                <img className="card-img-top p-1" src={appdev} alt="CardImg" />
                                <div className="card-body">
                                    <h5 className="card-title">Client Services</h5>
                                    <p className="card-text text-secondary">From Small Businesses to medium enterprises to globally leading MNC's we help for all businesses.</p>
                                    <a href="/appdevelopment" className="btn btn-warning text-light">Know More</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>



            {/* Product Section */}
            <section>
                <div className="container-fluid my-4" data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className="heading mx-2">
                        <h3 className='text-primary'><b>Vedalytics Products</b></h3>
                        {/* <div className='bg-warning' style={{ width: '300px', height: '3px', margin: 'auto' }}></div> */}
                        <p className="ser-content mx-auto my-2">Take a look at some of our Ready to use Developed
                            <span className='text-warning mx-1 '><b>Products.</b></span>
                        </p>
                    </div>

                    <div className="content row p-4" >
                        <div className="product col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className="card border-0 my-3 p-2" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }} >
                                <div className="card-body">
                                    <img src={guru} alt="guru" style={{ width: '65px' }} />
                                    <h6 className="card-title">ChatBot (Guru) - ChatBot Solutions</h6>
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;AI-ChatBot Solutions.</span>
                                    <br />
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;Voice & text based</span>
                                </div>
                                {/* <button className='btn btn-warning text-light'>Know More</button> */}
                            </div>
                        </div>
                        <div className="product col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className="card border-0 my-3 p-2" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }} >
                                <div className="card-body">
                                    <img src={prabha} alt="guru" style={{ width: '65px' }} />
                                    <h6 className="card-title">Prabhaamandal - IOT and Blockchain Solutions</h6>
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;Digital Wearables Advisor</span>
                                    <br />
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;Location based Services</span>
                                </div>
                                {/* <button className='btn btn-warning text-light'>Know More</button> */}
                            </div>
                        </div>
                        <div className="product col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className="card border-0 my-3 p-2" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }} >
                                <div className="card-body">
                                    <img src={vidya} alt="guru" style={{ width: '65px' }} />
                                    <h6 className="card-title">Vidya - Applied AI in Education System</h6>
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp; Cognitive Solution</span>
                                    <br />
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp; Guided communication</span>
                                </div>
                                {/* <button className='btn btn-warning text-light'>Know More</button> */}
                            </div>
                        </div>
                        <div className="product col-12 col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className="card border-0 my-3 p-2" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }} >
                                <div className="card-body">
                                    <img src={suraksha} alt="guru" style={{ width: '65px' }} />
                                    <h6 className="card-title">Suraksha - Security Framework  By Vedalytics</h6>
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;Enabling Auth & Auth</span>
                                    <br />
                                    <i className="bi bi-check-circle-fill text-primary"></i><span style={{ fontSize: '14px' }}>&nbsp;&nbsp;Flexible integration</span>
                                </div>
                                {/* <button className='btn btn-warning text-light'>Know More</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container-fluid my-4">
                    <div className="heading mx-2">
                        <h3 className='text-primary'><b>Vedalytics Corporate and Industrial Training</b></h3>
                        {/* <div className='bg-warning' style={{ width: '300px', height: '3px', margin: 'auto' }}></div> */}
                        <p className="ser-content mx-auto my-2">We provide Training to IT professionals whether you are Fresher or Experienced. our Expertise are <br /> AI/DS/ML/NLP, DB, DevOps, Cloud, Python, GCL, CGI, Templeton etc. </p>
                    </div>
                    {/* <div className="row p-4">
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* Corporate Training Section */}
            {/* <section className='mb-5'>
                <div className="bg-warning p-5">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="text-left">
                                <h4 className="ser-heading3"><span>Vedalytics Corporate and Industrial
                                    Training</span></h4>
                                <br />
                                <p className="ser-content3">We provide Training to IT professionals whether you are
                                    Fresher or Experienced. our Expertise are AI/DS/ML/NLP, DB, DevOps, Cloud, Python, GCL,
                                    CGI, Templeton etc.
                                </p>
                                <button className="btn btn-dark">Learn More</button>
                            </div>
                        </div>
                        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <img src={img} alt="img" />
                        </div>
                    </div>
                </div>
            </section> */}
            <BottomContent />
            <Footer />
        </>
    )
}
