import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import HeroImg from '../components/HeroImg'
import BottomContent from '../components/BottomContent'

import img1 from '../assets/HeroImg/about-bg.svg'
import { Link } from 'react-router-dom'
export default function About() {
  const HeroImgAbout = {
    heading: 'About Vedalytics',
    subheading: 'Welcome to the World of Development & Technology',
    text: `Our goal is to make the client's idea into realities and produce work that is beautiful and functional. We envisaged to commend the web plans and deploy technologies to build brands over the Internet. `,
    img: img1,
    validate: false
  }
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgAbout.heading}
        subheading={HeroImgAbout.subheading}
        text={HeroImgAbout.text}
        img={img1} 
        validate={HeroImgAbout.validate}/>

      {/* meet our team section */}
      <section>
        <div className="container"  data-aos="fade-up" data-aos-anchor-placement="top-center">
          <div className="heading text-center">
            <h4 className='text-primary'>Meet Our Team</h4>
            <div className='bg-warning' style={{ width: '300px', height: '3px', margin: 'auto' }}></div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Ramakant Roy</b></h5>
                  <h6 className="card-subtitle mb-2 text-muted">Co-founder and Director</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Aditya Kumar</b></h5>
                  <h6 className="card-subtitle mb-2 text-muted">Director (Operation)</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Dr. Gajendra Prasad Singh</b></h5>
                  <h6 className="card-subtitle mb-2 text-muted">Director (Legal)</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Kapil Kale</b></h5>
                  <h6 className="card-subtitle mb-2 text-muted">Director (Sales & Marketing)</h6>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Another Section */}
      <section className='mb-5' >
                <div className="p-5 text-center" style={{backgroundColor:'#3BB893'}}  data-aos="fade-up" data-aos-anchor-placement="top-center">
                   <h3 className='text-light'>Having Projects ?</h3>
                   <p  className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
                   <div className="button">
                    <button className='btn'><Link style={{textDecoration:'none',color:'green'}} to='/scheduledemo'>Schedule Demo</Link></button>
                   </div>
                </div>
            </section>

      <BottomContent />
      <Footer />
    </>
  )
}
