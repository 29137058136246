import React from 'react'
import Navbar from '../components/Navbar'
import HeroImg from '../components/HeroImg'
import DataIcon from '../assets/img/DataAnalysis.png';
import BottomContent from '../components/BottomContent';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ServicesCard from '../components/ServicesCard';
export default function DataAna() {
  const HeroImgData = {
    heading: 'Data & Analytics',
    subheading: 'Welcome to the World of Development & Technology',
    text: `At Vedalytics, we specialize in Data & Analytics services, helping businesses unlock the full potential of their data to drive growth, efficiency, and innovation. `,
    img: DataIcon,
    validate: false
  }
  const dataContext = [
    {
      img: DataIcon,
      heading: 'Data Strategy and Consulting',
      text: `We understand that data is at the heart of every successful business strategy. Our team of data experts works closely with you to define a comprehensive data strategy that aligns with your business goals. We assess your existing data infrastructure, identify data sources, and design a roadmap to capture, store, analyze, and visualize data effectively. With our data consulting services, you can harness the power of your data to gain a competitive edge in your industry.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: DataIcon,
      heading: 'Data Integration and Warehousing',
      text: `Unlocking the true value of data requires a solid foundation. Our experts specialize in data integration and warehousing, enabling you to bring together data from multiple sources into a centralized repository. We employ industry-leading technologies to transform and cleanse data, ensuring its accuracy and reliability. By establishing a robust data warehouse, you can access a holistic view of your business and make data-driven decisions with confidence`,
      bg: 'warning',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: DataIcon,
      heading: 'Data Analytics and Visualization',
      text: `Transforming raw data into actionable insights is our expertise. Our data analytics and visualization services empower you to extract meaningful information from your data. We utilize advanced analytics tools and techniques to analyze large datasets, identify trends, patterns, and correlations, and generate valuable insights. Through interactive and intuitive visualizations, we present data in a compelling and digestible manner, enabling you to make data-driven decisions with ease.`,
      bg: 'danger',
      headingColor: 'warning',
      textColor: 'light'
    },
    {
      img: DataIcon,
      heading: 'Predictive Analytics and Machine Learning',
      text: `Looking to forecast future outcomes or automate decision-making processes? Our team specializes in predictive analytics and machine learning. By leveraging advanced algorithms and models, we help you identify patterns, make accurate predictions, and optimize business processes. Whether it's predicting customer behavior, optimizing inventory levels, or detecting anomalies, our predictive analytics and machine learning solutions empower you to stay ahead of the curve.`,
      bg: 'success',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: DataIcon,
      heading: 'Data-driven Decision-Making',
      text: `Empowering your organization to make data-driven decisions is our ultimate goal. We equip you with the tools, technologies, and expertise needed to harness the power of data. By democratizing data access and fostering a data-driven culture within your organization, we enable teams at all levels to leverage insights and drive innovation.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    }
  ]
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgData.heading}
        subheading={HeroImgData.subheading}
        text={HeroImgData.text}
        img={DataIcon}
        validate={HeroImgData.validate}
      />
       <div className="container-fluid">
        {
          dataContext.map((card, i) => {
            return (
              <div className="row">
                <div className="col-md-12 my-1" data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <ServicesCard key={i} img={card.img} heading={card.heading} text={card.text} bg={card.bg} headingColor={card.headingColor} textColor={card.textColor} />
                </div>
              </div>
            )
          })
        }
      </div>
      {/* Another Section */}
      <section className='mb-5' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>
      <BottomContent/>
      <Footer/>
    </>
  )
}
