import React from 'react'
import Navbar from '../components/Navbar'
import BottomContent from '../components/BottomContent'
import Footer from '../components/Footer'
import HeroImg from '../components/HeroImg'
import AppDevImg from '../assets/img/AppDeve.png'
import ServicesCard from '../components/ServicesCard'
 
import { Link } from 'react-router-dom'

export default function AppDev() {
  const HeroImgAppDev = {
    heading: 'Application Development',
    subheading: 'Welcome to the World of Development & Technology',
    text: `Are you looking to create a powerful and user-friendly application for your business? Our expert team of application developers can help you bring your ideas to life `,
    img: AppDevImg,
    validate: false
  }

  const appdevcontext = [
    {
      img: AppDevImg,
      heading: 'Custom Application Development',
      text: 'We specialize in custom application development, tailoring solutions to meet the unique requirements of your business. Our developers work closely with you to understand your goals and objectives, ensuring that the final product aligns perfectly with your vision. From concept and design to development and deployment, we provide end-to-end services to create highly functional applications',
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AppDevImg,
      heading: 'Mobile Application Development',
      text: `In today's mobile-driven world, having a mobile application is essential to reach and engage with your audience. Our mobile application development services cover both iOS and Android platforms, utilizing native or hybrid frameworks to deliver exceptional user experiences. We focus on creating intuitive and visually appealing mobile apps that captivate users and drive business growth.`,
      bg: 'warning',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AppDevImg,
      heading: 'Web Application Development',
      text: `Web applications offer a dynamic and accessible way to interact with your customers. Our web application development expertise spans across various technologies and frameworks, including React, Angular, Node.js, and more. We build responsive and scalable web applications that are optimized for speed, performance, and security, ensuring a seamless user experience across different devices and browsers.`,
      bg: 'danger',
      headingColor: 'warning',
      textColor: 'light'
    },
    {
      img: AppDevImg,
      heading: 'E-commerce Application Development',
      text: `If you're planning to establish an online store, we can help you develop a feature-rich e-commerce application. Our team has experience working with popular e-commerce platforms like Magento, WooCommerce, Shopify, and more. We integrate secure payment gateways, implement inventory management systems, and create user-friendly interfaces to provide a seamless shopping experience for your customers.`,
      bg: 'success',
      headingColor: 'warning',
      textColor: 'light'
    },
    {
      img: AppDevImg,
      heading: 'Application Integration and Migration',
      text: `Already have an existing application but need to enhance its functionality or integrate it with other systems? We offer application integration services to streamline your business processes and improve efficiency. Additionally, if you're looking to migrate your application to a new platform or upgrade to the latest technology stack, our experts can handle the entire migration process seamlessly.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AppDevImg,
      heading: 'Maintenance and Support',
      text: `We understand that application development is an ongoing process, and we provide reliable maintenance and support services to ensure your application runs smoothly. Our team offers regular updates, bug fixes, and performance enhancements to keep your application up-to-date and secure. We are dedicated to providing prompt assistance and resolving any issues that may arise, allowing you to focus on your core business activities.`,
      bg: 'warning',
      headingColor: 'dark',
      textColor: 'light'
    }]
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgAppDev.heading}
        subheading={HeroImgAppDev.subheading}
        text={HeroImgAppDev.text}
        img={AppDevImg}
        validate={HeroImgAppDev.validate}
      />

      <div className="container-fluid">
        {
          appdevcontext.map((card, i) => {
            return (
              <div className="row">
                <div className="col-md-12 my-1" data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <ServicesCard key={i} img={card.img} heading={card.heading} text={card.text} bg={card.bg} headingColor={card.headingColor} textColor={card.textColor} />
                </div>
              </div>
            )
          })
        }
      </div>
      {/* Another Section */}
      <section className='mb-5' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>

      <BottomContent />
      <Footer />
    </>
  )
}
