import React from 'react'
import { Link } from 'react-router-dom'
import './HeroImg.css'

export default function HeroImg({ heading, subheading, text, img ,validate}) {
    return (
        <>
            {/* <div className="section" style={{ backgroundColor: '#FFF7E6' }}>
                <div className="container p-5 mt-5">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <h4>{heading}</h4>
                            <h3 className='text-warning'>{subheading}</h3>
                            <h6 className='my-3'>{text}</h6>
                            <button className='btn btn-warning text-light'><Link to='/scheduleDemo' style={{textDecoration:'none',color:'White'}}>Schedule a Demo</Link></button>
                        </div>
                        <div className=" imgDiv col-md-6 justify-content-center align-self-center col-12">
                            <img src={img} alt='img1' className='img-fluid ' style={{width:'',height:''}} />
                        </div>
                    </div>
                </div>
            </div> */}

            <section style={{ backgroundColor: '#FFF7E6' }}>
                <div className="main-area my-5 p-4" >
                    <div className="row mx-auto container my-5">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
                            <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1">{heading}</h3>
                            <h3 className="head-main  px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning">{subheading}</h3>
                            <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1">{text}</p>
                            <span className="px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1">
                                {validate? <button className='btn btn-warning text-light'><Link to='/scheduleDemo' style={{ textDecoration: 'none', color: 'White' }}>Schedule a Demo</Link></button>:null}
                             </span>
                        </div>

                        <div className=" d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <img className="bg-image img-fluid" src={img} alt="bg" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
