import React from 'react'
import logo from '../assets/logo/veda.png'
import { Link } from 'react-router-dom'
import "./Navbar.css"

export default function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top"
                style={{ backgroundColor: 'white', boxShadow: ' rgba(0, 0, 0, 0.15) 2.4px 3.4px 3.2px' }}>
                <Link className="navbar-brand" to="/"><img src={logo} alt='logo' style={{ height: "55px" }} /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto ">

                        <li className="nav-item dropdown active">
                            <Link className="nav-link dropdown-toggle text-dark mr-3" to="/" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Industries
                            </Link>
                            <div className="dropdown-menu border-0" aria-labelledby="navbarDropdown" style={{ width: '190px', fontSize: '13px' }}>
                                <Link className="dropdown-item" to="/">Education System</Link>
                                {/* <div className="dropdown-divider"></div> */}
                                <Link className="dropdown-item" to="/">Healthcare System</Link>
                                {/* <div className="dropdown-divider"></div> */}
                                <Link className="dropdown-item" to="/">BFSI System</Link>
                                {/* <div className="dropdown-divider"></div> */}
                                <Link className="dropdown-item" to="/">Profession Services</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown active">
                            <span className="nav-link dropdown-toggle text-dark mr-3 secondDD" to="/" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Product & Services
                            </span>
                            <div className="dropdown-menu  border-0" aria-labelledby="navbarDropdown" style={{ width: '210px', fontSize: '13px' }}>
                                <span className="nav-item dropend   active">
                                    <Link className="nav-link dropdown-toggle text-dark mr-3" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Vedalytics Lab & R&D
                                    </Link>
                                    <div className="dropdown-menu " aria-labelledby="navbarDropdown" style={{ width: '218px', fontSize: '13px' }}>
                                        <Link className="dropdown-item" to="/">Innovation Lab</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/">Guru - One Stop Shop <br />Chatbot Solution</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/">Vidya - AI Solution in <br />Education System</Link>
                                        <Link className="dropdown-item" to="/">Suraksha - Application<br /> Security</Link>
                                        <Link className="dropdown-item" to="/">Prabhaamandal – An IoT & <br /> Blockchain solution</Link>

                                    </div>
                                </span>
                                {/* <div className="dropdown-divider"></div> */}
                                <span className="nav-item dropend active">
                                    <Link className="nav-link dropdown-toggle text-dark mr-3" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        IT and Business Services
                                    </Link>
                                    <div className="dropdown-menu  " aria-labelledby="navbarDropdown" style={{ width: '218px', fontSize: '13px' }}>
                                        <Link className="dropdown-item" to="/appdev">Application<br/> Development</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/quality">Quality Assurance <br/> as a service</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/automation">Dev-Ops as a <br/> service</Link>
                                        <Link className="dropdown-item" to="/">Cloud Automation</Link>
                                        <Link className="dropdown-item" to="/">Intelligent Automation</Link>
                                        <Link className="dropdown-item" to="/dataana">Data & Analytics</Link>

                                    </div>
                                </span>
                                {/* <div className="dropdown-divider"></div> */}
                                <span className="nav-item dropdown active">
                                    <Link className="nav-link dropdown-toggle text-dark mr-3" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Our Engagement Model
                                    </Link>
                                    <div className="dropdown-menu border-0" aria-labelledby="navbarDropdown" style={{ width: '218px', fontSize: '13px' }}>
                                        <Link className="dropdown-item" to="/">Client Managed</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/">Co-Managed</Link>
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link className="dropdown-item" to="/">Vedalytics Managed</Link>
                                    </div>
                                </span>

                            </div>
                        </li>
                        <li className="nav-item active">
                            <Link className="nav-link text-dark mr-3" to="/about">AboutUs</Link>
                        </li>
                        <li className="nav-item active">
                            <Link to='/contact'> <button className='btn btn-dark'>Contact</button></Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}
