import React from 'react';
import './BottomContent.css';
import icon from '../assets/logo/veda.png'
import { Link } from 'react-router-dom'
import locIcon from '../assets/img/Group 273.svg'
import webIcon from '../assets/img/Group 274.svg'
import phoneIcon from '../assets/img/Group 331.svg'
import mailIcon from '../assets/img/Group 332.svg'
import canIcon from '../assets/img/canada.png'
export default function BottomContent() {
    return (
        <>
            <section className="my-5">
                <div className="p-5">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 p-0">
                            <img className="logo-bottom" src={icon} alt="logo" />
                            <p className="mt-3"><b>Vedalytics Software Solutions Pvt. Ltd</b></p>
                            <div className="d-flex p-0 my-1">
                                <img src={locIcon} alt="g1" />
                                <p className="f-content pl-2 m-0">811, 8th Floor, Vijaya Building, 17, Barakhamba Road, Connaught Place, New
                                    Delhi – 110001.</p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={webIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">www.vedalytics.com</p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={mailIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center"><a href="mailto:info@vedalytics.com"
                                    style={{ textDecoration: "none" }}>info@vedalytics.com</a></p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={phoneIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">+91-11-23739579</p>
                            </div>

                        </div>
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5"><b>Company</b></p>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link className='text-dark' to='/about'>About Us</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link className='text-dark' to='/contact'>Contact Us</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link className='text-dark' to='/privacy'>Privacy Policy</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link className='text-dark' to='/terms'>Terms & Conditions</Link></li>
                        </div>
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5"><b>India Offices</b></p>
                            <li className='f-content' style={{ listStyle: 'none' }}> Delhi </li>
                            <li className='f-content' style={{ listStyle: 'none' }}> Noida </li>
                            <li className='f-content' style={{ listStyle: 'none' }}> Hyderabad </li>
                            <li className='f-content' style={{ listStyle: 'none' }}>Bangalore</li>
                        </div>
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5"><b>Worldwide Footprints</b></p>
                            <div className="phone d-flex p-0 my-1">
                                <img src={canIcon} alt="icon" style={{ width: '45px' }} />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">Canada</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center bg-light" style={{ width: '100%' }}>
                    © Copyright 2021. Vedalytics. All rights reserved.
                </div>
            </section>
        </>
    )
}
