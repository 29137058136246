import React from 'react'
import './Footer.css'

export default function Footer() {
    return (
        <>
            <footer className="footer fixed-bottom bg-light" style={{ height: '65px' }}>
                <div className="container footerText">
                    <span className="">Follow Us on...</span>
                    <a href="https://www.facebook.com/Vedalytics-Software-Solutions-Pvt-Ltd-100287189409351" target="_blank" rel="noopener noreferrer"> <i className="bi bi-facebook mx-2" style={{ fontSize: '35px', color: '#3b5998' }}></i></a>
                    <a href="https://twitter.com/vedalytics" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter mx-2" style={{ fontSize: '35px', color: '#00acee' }}></i></a>
                    <a href="https://www.linkedin.com/company/vedalytics-solutions/mycompany/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin mx-2" style={{ fontSize: '35px', color: '#0A66C2' }}></i></a>
                </div>
            </footer>
        </>
    )
}
