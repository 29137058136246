import React from 'react'
import Navbar from '../components/Navbar'
import HeroImg from '../components/HeroImg'
import AutoIcon from '../assets/img/Devops.png';
import BottomContent from '../components/BottomContent';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ServicesCard from '../components/ServicesCard';

export default function Automation() {
  const HeroImgAuto = {
    heading: 'Automation & Devops',
    subheading: 'Welcome to the World of Development & Technology',
    text: `At Vedalytics, we specialize in Automation and DevOps services, helping businesses optimize their software development lifecycle and drive continuous innovation.`,
    img: AutoIcon,
    validate: false
  }

  const automationContext = [
    {
      img: AutoIcon,
      heading: 'Automation',
      text: `Automation is the key to eliminating manual, repetitive tasks and streamlining workflows. By leveraging automation tools and frameworks, we enable businesses to achieve higher efficiency, reduced human error, and accelerated time-to-market.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AutoIcon,
      heading: 'Continuous Integration and Continuous Deployment (CI/CD)',
      text: `Our DevOps engineers implement CI/CD practices to ensure a smooth and continuous software delivery pipeline. We integrate automated build, test, and deployment processes into a cohesive workflow, enabling rapid and reliable software releases. With CI/CD, your development team can collaborate more effectively, detect and resolve issues early in the development cycle, and deliver new features and updates to your customers with greater agility.`,
      bg: 'warning',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AutoIcon,
      heading: 'Configuration Management ',
      text: `Efficient configuration management is vital for maintaining consistency and reducing errors across your infrastructure. We leverage tools like Puppet, Chef, or Ansible to automate the configuration and management of servers, databases, networking, and other components. With configuration management, we enable reliable and repeatable deployments, enforce infrastructure standards, and simplify the process of scaling your applications.`,
      bg: 'danger',
      headingColor: 'warning',
      textColor: 'light'
    },
    {
      img: AutoIcon,
      heading: 'Continuous Monitoring and Logging',
      text: `To ensure the stability and performance of your applications, we implement continuous monitoring and logging practices. We utilize monitoring tools like Prometheus, Grafana, or ELK stack to collect real-time data on application performance, resource utilization, and user experience. By analyzing logs and metrics, we proactively identify bottlenecks, troubleshoot issues, and optimize your infrastructure and applications for maximum efficiency.`,
      bg: 'success',
      headingColor: 'dark',
      textColor: 'light'
    },
    {
      img: AutoIcon,
      heading: 'Security and Compliance',
      text: `Security is a paramount concern in any software development process. We integrate security measures into every stage of the DevOps lifecycle, implementing security scanning, vulnerability assessments, and code analysis tools. Our team ensures compliance with industry regulations and standards, protecting your applications and data from potential threats. By adopting DevSecOps principles, we prioritize security and establish a strong foundation for your digital products.`,
      bg: 'primary',
      headingColor: 'dark',
      textColor: 'light'
    }
  ]
  return (
    <>
      <Navbar />
      <HeroImg
        heading={HeroImgAuto.heading}
        subheading={HeroImgAuto.subheading}
        text={HeroImgAuto.text}
        img={AutoIcon}
        validate={HeroImgAuto.validate}
      />

      <div className="container-fluid">
        {
          automationContext.map((card, i) => {
            return (
              <div className="row">
                <div className="col-md-12 my-1" data-aos="fade-up" data-aos-anchor-placement="top-center">
                  <ServicesCard key={i} img={card.img} heading={card.heading} text={card.text} bg={card.bg} headingColor={card.headingColor} textColor={card.textColor} />
                </div>
              </div>
            )
          })
        }
      </div>
      {/* Another Section */}
      <section className='mb-5' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>

      <BottomContent />
      <Footer />
    </>
  )
}
