import React from 'react'
import { Link } from 'react-router-dom'
import BottomContent from './BottomContent'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Privacy() {
    return (
        <div>
            <Navbar />
            <section>
                <div className="px-2 px-sm-2 px-md-5 px-lg-5 px-xl-5 py-2 py-sm-2 py-md-5 py-lg-5 py-xl-5">
                    <div className="container my-5">
                        <div className="text-center">
                            <h2 className="mt-5 mt-sm-5 mt-md-3 mt-lg-3 mt-xl-3 px-3 px-sm-3 px-md-1 px-lg-3 px-xl-1">
                                <b>Privacy Policy</b>
                            </h2>
                        </div>
                        <div className="my-4">
                            <p className="text-secondary">By accessing this website we assume you have read Our Privacy Policy in full. This
                                will help you to know what Information we collect and how we will use your collected information and Data.
                            </p>
                            <h5><b>Information that we collect from you</b></h5>
                            <p className="text-secondary">While visiting our website, we may collect and process the below mentioned
                                information about you:</p>
                            <div className="inner-content">
                               <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Purpose about your use of our
                                    website include information of your visits like pages viewed and the resources that you access. And, such
                                    information includes traffic data, communication data and location data.</span>
                            </div>
                            <h5 className="mt-4"><b>Use of Cookies</b></h5>
                            <p className="text-secondary">Cookies provide information regarding the computer used by a visitor. We may use
                                cookies to gather data about your computer so that it can assist us in enhancing our website. By using the
                                cookie feature, we may also collect information about the general Internet use. As we said earlier as well,
                                that the information that we save will not identify you personally. It's just a statistical data. And, this
                                data does not identify any personal details whatsoever.</p>
                            <h5 className="mt-4"><b>How we Use Information we collect from you</b></h5>
                            <div className="inner-content">
                               <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;To quickly provide you the glance
                                    of information that you requested from us related to our products or services</span><br />
                               <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;To help you provide the
                                    information related to other products that may be of interest to you. But such additional information will
                                    only be sent to you, if you have consented to receive the information.</span><br />
                               <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;And, to notify you of the changes
                                    to our website, services and products.</span>
                            </div>
                            <h5 className="mt-4"><b>Saving your personal information</b></h5>
                            <p className="text-secondary">By providing your personal information, you agree to this access. We do our level
                                best to ensure that all the required steps are taken to ensure that your data is saved securely.
                                Unfortunately, the information you are sending via the Internet is not fully secure and it can be
                                intercepted easily. We cannot guarantee the data security that you send us electronically because sending
                                such information is completely at your own risk.</p>
                            <h5 className="mt-5"><b>Imbibing third party links or websites</b></h5>
                            <p className="text-secondary">Sometimes, we may include links to the third parties on the website. And, even we
                                may provide a link of third parties but that does not mean that we endorse or approve our site’s policy
                                towards visitor privacy. Do review the third party privacy policy before sending the visitors any personal
                                data.</p>
                            <h5 className="mt-4"><b>Your acceptance to this policy & terms</b></h5>
                            <p className="text-secondary">If you are using our website, you signify your acceptance of this privacy policy.
                                And, if you don't agree to the policy, please do not use Vedalytics Website. Your continued use of
                                Vedalytics site following the sharing of changes to this privacy policy will be deemed as your acceptance of
                                those updates and changes.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="bg-success p-5">
                    <div className="d-flex  justify-content-center ">
                    <h4 className="text-white  align-self-center">Are you Ready for Awesomeness ?</h4>
                    <button class="btn bg-light text-success py-2 px-2 mx-3 justify-content-center align-self-center"><Link to='/contact' style={{textDecoration:'none'}}> Contact Us</Link></button>
                    </div>
                </div>
            </section>
            <BottomContent />
            <Footer />
        </div>
    )
}
