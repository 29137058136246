import React, { useState } from 'react'
import './ScheduleDemo.css'
import Footer from './Footer'
import Navbar from './Navbar'
import CountryCode from "../Data/CountryCode"
// import swal from 'sweetalert2'
import axios from 'axios'
import { Link } from 'react-router-dom'


export default function ScheduleDemo() {

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [countrycode, setCountryCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState(false);

  const handleSendData = () => {

    if (email.length === 0 || mobile.length === 0) {
      setError(true)
    } else {

      const url = "https://vedalytics.com/scheduleData.php"
      let fData = new FormData();
      fData.append('firstname', firstname);
      fData.append('lastname', lastname);
      fData.append('email', email);
      fData.append('company', company);
      fData.append('countrycode', countrycode);
      fData.append('mobile', mobile);

      axios.post(url, fData)
        .then(response => alert("Your form has been submitted successfully!"))
        .catch(error => alert("Something went wrong!"));

      setFirstName('');
      setLastName('');
      setEmail('');
      setCompany('');
      setCountryCode('');
      setMobile('');
    }
  }

  return (
    <>
      <Navbar />

      <div className="container border-0 p-3" style={{ marginTop: '100px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' }}>
        <div className="heading text-center">
          <h4>Talk to Vedalytics Experts</h4>
          <div className='bg-warning' style={{ width: '350px', height: '3px', margin: 'auto' }}></div>
        </div>
        <div className="content">
          <div className="row p-1">
            <div className="col-md-6" >
              <div className="input-field">
                <label htmlFor="firstname">First Name</label>
                <input type="text" className='form-control' value={firstname} onChange={(e) => setFirstName(e.target.value)} placeholder='Enter your firstname' />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-field">
                <label htmlFor="lastname">Last Name</label>
                <input type="text" className='form-control' value={lastname} onChange={(e) => setLastName(e.target.value)} placeholder='Enter your lastname' />
              </div>
            </div>
          </div>
          <div className="input-field p-1">
            <label htmlFor="email">Email Id<span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
            <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' required />
            {error && email.length<=0 ? <span style={{ color: 'red' }}>email id required</span> : ''}
          </div>
          <div className="input-field p-1">
            <label htmlFor="company">Company</label>
            <input type="text" className='form-control' value={company} onChange={(e) => setCompany(e.target.value)} placeholder='Enter your company name' />
          </div>
          <div className="input-field p-1">
            <label htmlFor="country">Country & Code</label>
            <select name="countrycode" id="countrycode" className='form-control' value={countrycode} onChange={(e) => setCountryCode(e.target.value)}>
              <option value="" disabled>--Select Country--</option>
              {
                CountryCode.map((country, i) => {
                  return <option key={i} >{country.name} ({country.dial_code})</option>
                })
              }
            </select>
          </div>
          <div className="input-field p-1">
            <label htmlFor="number">Mobile Number<span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
            <input type="number" className='form-control' value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Enter your mobile' required />
            {error && mobile.length<=0 ? <span style={{ color: 'red' }}>mobile number required</span> : ''}
          </div>
          <div className="condition p-1">
            <p>By submitting your information in this form you are agreeing to the terms listed on our Privacy Policy. <b>Vedalytics</b> will only use this information to contact you about the products and services you're requested.</p>
          </div>
          <div className="button p-1 mb-0">
            <button onClick={handleSendData} className='btn btn-warning text-light' style={{ width: '100%' }}>send</button><button className='btn btn-danger my-2'><Link to='/' style={{textDecoration:'none',color:'white'}}>Back to Home</Link></button>
          </div>

        </div>
      </div>

      <Footer />
    </>
  )
}
